import { create } from 'zustand';

import type { PageLoaderSlice } from './pageLoaderSlice';

import { createPageLoaderSlice } from './pageLoaderSlice';

type Store = PageLoaderSlice;

export const useStore = create<Store>()((...a) => ({
  ...createPageLoaderSlice(...a),
}));
