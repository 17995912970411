import type { StateCreator } from 'zustand';

interface State {
  isLoading: boolean;
  dimmed: boolean;
}
interface Action {
  startLoading: ({ dimmed }: { dimmed: boolean }) => void;
  endLoading: () => void;
}
export type PageLoaderSlice = State & Action;

const initialState: State = {
  isLoading: false,
  dimmed: false,
};

export const createPageLoaderSlice: StateCreator<PageLoaderSlice, [], []> = (set) => ({
  ...initialState,
  startLoading: ({ dimmed }) => set({ isLoading: true, dimmed }),
  endLoading: () => set(initialState),
});
